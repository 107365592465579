import { Container, Navbar, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import LoginImg from "../../assets/images/login-bg.png";
import avtar from "../../assets/images/User.png";
import { GoChevronDown } from "react-icons/go";
import { RiLogoutCircleLine } from "react-icons/ri";
import { useEffect, useState } from "react";
import ToggleSwitch from "../Common/ToggleSwitch/ToggleSwitch";
const Header = () => {
  const [type, setType] = useState<string>(
    localStorage.getItem("pasteType") || "1"
  );

  useEffect(() => {
    localStorage.setItem("pasteType", type);
  }, [type]);

  return (
    <>
      <header className="site-header">
        <Container fluid className="header-nav">
          <Navbar expand="lg">
            <Navbar.Brand className="me-5">
              <img src={LoginImg} alt="" style={{ width: "50px" }} />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <div className="d-flex align-items-center ms-auto gap-3">
              <div className="text-center">
                <ToggleSwitch
                  label="Using Akruti"
                  value={type === "1"}
                  changeValue={(val: boolean) => setType(val ? "1" : "0")}
                />
              </div>
              <Dropdown className="profile-dd">
                <Dropdown.Toggle>
                  <div className="d-flex gap-2">
                    <div>
                      <img
                        src={avtar}
                        width={35}
                        height={35}
                        className=" rounded-circle object-fit-cover"
                        alt=""
                      />
                    </div>
                    <div>
                      <p className="font-14 mb-0 text-dark font-medium">
                        {localStorage.getItem("user_name")}
                      </p>
                      <p className="font-12 mb-0 text-secondary">
                        {localStorage.getItem("user_email_id")}
                      </p>
                    </div>
                  </div>
                  <GoChevronDown size={16} className="icon" />
                </Dropdown.Toggle>
                <Dropdown.Menu align="end">
                  {/* <Link to="" className=" dropdown-item"> <SlUser size={14} className="me-2" /> My Profile</Link> */}
                  {/* <Link to="/change-password" className=" dropdown-item" onClick={() => {                   
                  }}>

                    <RiKeyFill
                      size={14}
                      className="me-2"

                    />{" "}
                    Change Password
                  </Link> */}

                  <Link
                    to="/login"
                    className=" dropdown-item"
                    onClick={() => {
                      localStorage.clear();
                    }}
                  >
                    <RiLogoutCircleLine size={14} className="me-2" /> Logout
                  </Link>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Navbar>
        </Container>
      </header>
    </>
  );
};
export default Header;
