import React from "react";
import "./ToggleSwitch.css";

interface TogglePropData {
  label: string;
  value: boolean;
  changeValue: any;
}

const ToggleSwitch = (props: TogglePropData) => {
  return (
    <div>
      {props.label} &nbsp;&nbsp;&nbsp;&nbsp;
      <div className="toggle-switch">
        <input
          type="checkbox"
          className="checkbox"
          name={props.label}
          id={props.label}
          checked={props.value}
          onChange={(e) => {
            props.changeValue(e.target.checked);
          }}
        />
        <label className="label" htmlFor={props.label}>
          <span className="inner" />
          <span className="switch" />
        </label>
      </div>
    </div>
  );
};

export default ToggleSwitch;
