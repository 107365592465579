import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const DefaultPage = () => {
    const navigate = useNavigate();

    useEffect(() => {
        if (localStorage.getItem("access_token") === null) {
            navigate("/login");
        } else {
            navigate("/schools")
        }
    }, []);

    return (
        <>
        </>
    )
}
export default DefaultPage;